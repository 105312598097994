<template>
	<v-row>
		<v-col cols="12">
			<v-data-table :headers="xapi_headers" :items="xapi_statements" :items-per-page="20" multi-sort :search="xapi_search" :loading="appLoading > 0">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field v-model="xapi_search" label="Search" prepend-icon="mdi-magnify" class="mx-4"></v-text-field>
						</v-col>
					</v-row>
				</template>
				<template v-slot:top>
					<v-dialog v-model="dialog" max-width="1200px">
						<template v-slot:activator="{ on, attrs }">
							<v-row>
								<v-spacer></v-spacer>
								<v-col cols="auto">
									<v-btn color="success" elevation="2" fab small class="mr-4" v-bind="attrs" v-on="on">
										<v-icon>{{ $icons.new }}</v-icon>
									</v-btn>
								</v-col>
							</v-row>

						</template>
						<v-card>
							<v-card-title>
								<span class="text-h3">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12" lg="8">
											<v-autocomplete v-model="editedItem.resource_id" :items="resources" :item-text="(item) => `${item.brand} | ${item.title}`" item-value="id"></v-autocomplete>
											<v-col cols="12">
												<v-row>
													<v-col cols="12" lg="6">
														<v-text-field v-model="editedItem.score_points" type="number" min="0" label="Score points"></v-text-field>
													</v-col>
													<v-col cols="12" lg="6">
														<v-text-field v-model="editedItem.pass_points" type="number" min="0" label="Pass points"></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" lg="6">
														<v-text-field v-model="editedItem.score_percent" type="number" step="10" min="0" max="100" label="Score percent"></v-text-field>
													</v-col>
													<v-col cols="12" lg="6">
														<v-text-field v-model="editedItem.pass_percent" type="number" step="10" min="0" max="100" label="Pass percent"></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12">
														<v-checkbox v-model="editedItem.passed" label="Passed"></v-checkbox>
													</v-col>
												</v-row>
											</v-col>

										</v-col>
										<v-col cols="12" lg="4">
											<v-row justify="center">
												<v-date-picker color="primary" v-model="editedItem.created_at"></v-date-picker>
											</v-row>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
								<v-btn color="success" @click="save">Save</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5 text-center">Are you sure you want to delete this item?</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="grey darken-1" text @click="closeDelete">Cancel</v-btn>
								<v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</template>
				<template v-slot:item.score_percent="{ item }">
					{{ item.score_percent }}%
				</template>
				<template v-slot:item.passed="{ item }">
					<v-icon v-if="item.passed" color="success">mdi-check</v-icon>
					<v-icon v-else color="error">mdi-close</v-icon>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
					<v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
				</template>
			</v-data-table>

		</v-col>
	</v-row>
</template>

<script>
import {toast} from "../../util/helpers";
import Models from "../../models";

export default {
	name: "XapiStatements",
	props: {
		user_id: Number
	},
	computed: {
		formTitle() {
			return this.editedIndex === -1 ? 'New Xapi Statement' : 'Edit Xapi Statement'
		},
	},
	data: () => ({
		xapi_loading: false,
		resources: [],
		dialog: false,
		dialogDelete: false,
		editedIndex: -1,
		editedItem: Models.XapiStatement(),
		defaultItem: Models.XapiStatement(),
		xapi_statements: [],
		xapi_search: null,
		xapi_headers: [
			{text: 'ID', align: 'start', sortable: true, value: 'id'},
			{text: 'Brand', align: 'start', sortable: true, value: 'resource_brand'},
			{text: 'Course ID', align: 'start', sortable: true, value: 'resource_id'},
			{text: 'Title', align: 'start', sortable: true, value: 'resource_title'},
			{text: 'Score', align: 'start', sortable: true, value: 'score_percent'},
			{text: 'Passed', align: 'start', sortable: true, value: 'passed'},
			{text: 'Date', align: 'start', sortable: true, value: 'created_at'},
			{text: 'Actions', value: 'actions', sortable: false},
		],
	}),
	mounted() {
		this.getXapiStatements();
		this.getCourses();
	},
	watch: {
		user_id() {
			this.getXapiStatements();
		},
		editedItem: {
			handler: function (val, oldVal) {
				val.passed = val.score_points >= val.pass_points || val.score_percent >= val.pass_percent
			},
			deep: true
		},
	},
	methods: {
		editItem(item) {
			this.editedIndex = this.xapi_statements.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true
		},

		deleteItem(item) {
			this.editedIndex = this.xapi_statements.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},

		deleteItemConfirm() {
			this.$api.xapi_statements.delete(this.user_id, this.editedItem.id)
				.then(response => this.xapi_statements = response.data)
				.catch(error => toast.add(error.message, 'error'))
				.finally(() => this.closeDelete())
		},

		close() {
			this.dialog = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		save() {
			if (this.editedIndex > -1) {
				this.$api.xapi_statements.update(this.user_id, this.editedItem.id, this.editedItem)
					.then(response => this.xapi_statements = response.data)
					.catch(error => toast.add(error.message, 'error'))
			} else {
				this.$api.xapi_statements.create(this.user_id, this.editedItem)
					.then(response => this.xapi_statements = response.data)
					.catch(error => toast.add(error.message, 'error'))
			}
			this.close()
		},
		getXapiStatements() {
			if (this.user_id) {

				this.$api.xapi_statements.index(this.user_id)
					.then(response => this.xapi_statements = response.data)
					.catch(error => toast.add(error.message, 'error'))

			}
		},
		getCourses() {
			this.$api.courses.index()
				.then(response => this.resources = response.data)
				.catch(error => console.log(error))
		}
	}
}
</script>